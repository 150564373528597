<template>
  <div>
    <Toast :breakpoints="{'920px': {width: '100%', right: '0', left: '0'}}" />
    <div class="card">
      BookmarksCrud {{perf}}
      <Toolbar class="mb-4">
        <template #start>
          <Button label="New" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
          <Button label="Delete" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedBookmarks || !selectedBookmarks.length" />
        </template>
        <template #end>
          <Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
        </template>
      </Toolbar>
      <DataTable class="p-datatable-sm"
        ref="dt" :value="bookmarks" v-model:selection="selectedBookmarks" dataKey="id" 
        :paginator="true" :rows="50" :filters="filters"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50,75,100]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
        responsiveLayout="scroll"
        stripedRows >
        <template #header>
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" placeholder="キーワード" />
          </span>
        </template>            
        <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>
        <Column style="width: 3rem" :exportable="false">
          <template #body="slotProps">
            <Menu ref="menu1" :popup="true" :model="items"></Menu>
            <Button icon="pi pi-ellipsis-v" class="p-button-secondary p-button-outlined h-2rem w-2rem" @click="toggle_setBookmarkCd($event, slotProps.data.bookmark_cd)"></Button>
          </template>
        </Column>  
        <Column field="trendchart_file" header="trendchart_file">
          <template #body="slotProps">
            <router-link :to="{name:'meigarachart',params:{code:slotProps.data.bookmark_cd}}">
              <img class="trendchart-img" :src="'https://tradersr.com' + slotProps.data.trendchart_file" :alt="slotProps.data.trendchart_file"/>
            </router-link>
          </template>
        </Column>
        <Column field="bookmark_name_ja" header="name_ja" :sortable="true">
          <template #body="slotProps">
            <router-link :to="{name:'meigarasearch_ext',params:{code:slotProps.data.bookmark_cd}}">{{slotProps.data.bookmark_name_ja}}</router-link>
          </template>
        </Column>
        <Column field="bookmark_cd" header="cd" :sortable="true">
          <template #body="slotProps">
            {{slotProps.data.bookmark_cd}}
          </template>
        </Column> 
        <Column field="id" header="ID" :sortable="true"></Column>
        <Column field="userid" header="User ID" :sortable="true"></Column>
        <Column field="bookmark_type" header="type" :sortable="true"></Column>
        <Column field="bookmark_group" header="group" :sortable="true"></Column>
        <Column field="bookmark_name_en" header="name_en" :sortable="true"></Column>
        <Column field="bookmark_desc" header="desc" :sortable="true"></Column>
        <Column field="public_flag" header="public_flag" :sortable="true"></Column>
        <Column field="public_category" header="public_category" :sortable="true"></Column>
        <Column field="u_date" header="更新日" :sortable="true"></Column>
        <Column :exportable="false"  style="min-width:8rem">
          <template #body="slotProps">
            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editBookmark(slotProps.data)" />
            <Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteBookmark(slotProps.data)" />
          </template>
        </Column>
      </DataTable>
    </div>

    <Dialog v-model:visible="bookmarkDialog" :style="{width: '450px'}" header="ブックマーク" :modal="true" class="p-fluid">
      <div class="field">
        <label for="userid">User ID</label>
        <InputText id="userid" v-model.trim="bookmark.userid" required="true" autofocus :class="{'p-invalid': submitted && !bookmark.userid}" />
        <small class="p-error" v-if="submitted && !bookmark.userid">User ID is required.</small>
      </div>
      <div class="field">
        <label for="bookmark_type">bookmark_type</label>
        <InputText id="bookmark_type" v-model.trim="bookmark.bookmark_type" required="true" />
      </div>
      <div class="field">
        <label for="bookmark_group">bookmark_group</label>
        <InputText id="bookmark_group" v-model.trim="bookmark.bookmark_group" required="true" />
      </div>
      <div class="field">
        <label for="bookmark_cd">bookmark_cd</label>
        <InputText id="bookmark_cd" v-model.trim="bookmark.bookmark_cd" required="true" autofocus :class="{'p-invalid': submitted && !bookmark.bookmark_cd}"/>
        <small class="p-error" v-if="submitted && !bookmark.bookmark_cd">bookmark_cd is required.</small>
      </div>
      <div class="field">
        <label for="bookmark_name_ja">bookmark_name_ja</label>
        <InputText id="bookmark_name_ja" v-model.trim="bookmark.bookmark_name_ja" required="true" autofocus :class="{'p-invalid': submitted && !bookmark.bookmark_name_ja}"/>
        <small class="p-error" v-if="submitted && !bookmark.bookmark_name_ja">bookmark_name_ja is required.</small>
      </div>
      <div class="field">
        <label for="bookmark_name_en">bookmark_name_en</label>
        <InputText id="bookmark_name_en" v-model.trim="bookmark.bookmark_name_en" required="true" autofocus :class="{'p-invalid': submitted && !bookmark.bookmark_name_en}"/>
        <small class="p-error" v-if="submitted && !bookmark.bookmark_name_en">bookmark_name_en is required.</small>
      </div>   
      <div class="field">
        <label for="bookmark_desc">bookmark_desc</label>
        <Textarea id="bookmark_desc" v-model="bookmark.bookmark_desc" required="true" rows="2" cols="20" />
      </div>
      <div class="field">
        <label for="public_flag">public_flag</label>
        <InputText id="public_flag" v-model.trim="bookmark.public_flag" required="true" />
      </div>       
      <div class="field">
        <label for="public_category">public_category</label>
        <InputText id="public_category" v-model.trim="bookmark.public_category" required="true" />
      </div>        
      <div class="field">
        <label for="u_date">更新日</label>
        <InputText id="u_date" v-model.trim="bookmark.u_date" required="true" />
      </div>
      <div class="field">
        <label for="passcode">パスコード</label>
        <InputText id="passcode" v-model.trim="bookmark.passcode" required="true" />
      </div>      
      <template #footer>
        <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
        <Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveBookmark" />
      </template>
    </Dialog>

    <Dialog v-model:visible="deleteBookmarkDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="bookmark">Are you sure you want to delete <b>{{bookmark.bookmark_name_ja}}</b>?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteBookmarkDialog = false"/>
        <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteBookmark" />
      </template>
    </Dialog>

    <Dialog v-model:visible="deleteBookmarksDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="bookmark">Are you sure you want to delete the selected bookmarks?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteBookmarksDialog = false"/>
        <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedBookmarks" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import BookmarksCrudService from '@/service/BookmarksCrudService';
import axios from 'axios';

export default {
  data() {
    return {
      perf: null,
      bookmarks: null,
      bookmarkDialog: false,
      deleteBookmarkDialog: false,
      deleteBookmarksDialog: false,
      bookmark: {},
      selectedBookmarks: null,
      selectedBookmarkCd: null,
      filters: {},
      submitted: false,
      items: [
        {
          label: '銘柄拡張検索',
          command: () => {
            this.$router.push('/meigarasearch_ext/' + this.selectedBookmarkCd);
					}
        },
        {
          label: '銘柄ニュース',
          command: () => {
            this.$router.push('/meigaranewsrecentl/gridm' + this.selectedBookmarkCd);
					}
        },
        {
          label: '銘柄チャート',
          command: () => {
            this.$router.push('/meigarachart/' + this.selectedBookmarkCd);
					}
        },
      ],
    }
  },
  bookmarksCrudService: null,
  created() {
    this.bookmarksCrudService = new BookmarksCrudService();
    this.initFilters();
  },
  mounted() {
    this.bookmarksCrudService.getAll(this.$route.params.code)
    .then(d => {
      this.bookmarks = d.data.bookmarks;
      this.perf = d.perf;
    });
  },
  methods: {
    openNew() {
      this.bookmark = {};
      this.submitted = false;
      this.bookmarkDialog = true;
    },
    hideDialog() {
      this.bookmarkDialog = false;
      this.submitted = false;
    },
    saveBookmark() {
      this.submitted = true;

      if (this.bookmark.bookmark_name_ja.trim()) {
        let param_bookmark = {
          userid: this.bookmark.userid,
          bookmark_type: this.bookmark.bookmark_type,
          bookmark_group: this.bookmark.bookmark_group,
          // trendchart_file: this.bookmark.trendchart_file,
          bookmark_cd: this.bookmark.bookmark_cd,
          bookmark_name_ja: this.bookmark.bookmark_name_ja,
          bookmark_name_en: this.bookmark.bookmark_name_en,
          bookmark_desc: this.bookmark.bookmark_desc,
          public_flag: this.bookmark.public_flag,
          public_category: this.bookmark.public_category,
          u_date: this.bookmark.u_date,
          passcode: this.bookmark.passcode
        };

        if (this.bookmark.id) {
          console.log('既存ブックマークの更新'); 
          axios.patch('https://api.tradersr.com/api/bookmarkscrud/' + this.bookmark.id, param_bookmark)
          .then(response => {
            if(response.data == 'pwinvalid'){
              this.$toast.add({severity:'error', summary: 'ブックマーク更新', detail:param_bookmark.bookmark_cd + ' ' + param_bookmark.bookmark_name_ja +' の更新に失敗しました (pwinvalid)', life: 2000});
            }else{
              this.bookmark = response.data.data;
              this.bookmarks[this.findIndexById(this.bookmark.id)] = this.bookmark;

              this.$toast.add({severity:'success', summary: 'ブックマーク更新', detail:this.bookmark.bookmark_cd + ' ' + this.bookmark.bookmark_name_ja +' を更新しました', life: 2000});
            }
          })
          .catch(e => {
            console.log(e);
            this.$toast.add({severity:'error', summary: 'ブックマーク更新', detail:e, life: 2000});
          })
        } else {
          console.log('新規ブックマークの登録');
          axios.post('https://api.tradersr.com/api/bookmarkscrud', param_bookmark)
          .then(response => {
            console.log(response);

            if(response.data == 'pwinvalid'){
              this.$toast.add({severity:'error', summary: 'ブックマーク登録', detail:param_bookmark.bookmark_cd + ' ' + param_bookmark.bookmark_name_ja +' の登録に失敗しました (pwinvalid)', life: 2000});
            }
            else if(response.data == 'existing'){
              this.$toast.add({severity:'warn', summary: 'ブックマーク登録', detail:param_bookmark.bookmark_cd + ' ' + param_bookmark.bookmark_name_ja +' は既に登録されています', life: 2000});
            }else{
              //this.$toast.add({severity:'success', summary: 'ブックマーク', detail:param_bookmark.bookmark_cd + ' ' + this.param_bookmark.bookmark_name_ja +' をブックマークしました。', life: 3000});

              this.bookmark = response.data.data;
              if(this.bookmark){
                this.bookmarks.push(this.bookmark);
              }

              this.$toast.add({severity:'success', summary: 'ブックマーク登録', detail:this.bookmark.bookmark_cd + ' ' + this.bookmark.bookmark_name_ja +' を登録しました', life: 2000});
              // console.log('success-1');
            }
          })
          .catch(e => {
            console.log(e);
            this.$toast.add({severity:'error', summary: 'ブックマーク登録', detail:e, life: 2000});
          })
        }

        this.bookmarkDialog = false;
        this.bookmark = {};
      }
    },
    editBookmark(bookmark) {
      this.bookmark = {...bookmark};
      this.bookmarkDialog = true;
    },
    confirmDeleteBookmark(bookmark) {
      this.bookmark = bookmark;
      this.deleteBookmarkDialog = true;
    },
    deleteBookmark() {
      this.bookmarks = this.bookmarks.filter(val => val.id !== this.bookmark.id);
      axios.delete('https://api.tradersr.com/api/bookmarkscrud/' + this.bookmark.id)
        .then(response => {
          console.log(response);
          this.$toast.add({severity:'success', summary: 'ブックマーク削除', detail:response.data.data +' を削除しました', life: 2000});
        })
        .catch(e => {
          console.log(e);
          this.$toast.add({severity:'error', summary: 'ブックマーク削除', detail:e, life: 2000});
        })
      this.deleteBookmarkDialog = false;
      this.bookmark = {};
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.bookmarks.length; i++) {
        if (this.bookmarks[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteBookmarksDialog = true;
    },
    deleteSelectedBookmarks() {
      for (let i=0; i<this.selectedBookmarks.length; i++){
        console.log(this.selectedBookmarks[i].bookmark_name_ja);

        axios.delete('https://api.tradersr.com/api/bookmarkscrud/' + this.selectedBookmarks[i].id)
          .then(response => {
            console.log(response);
          })
          .catch(e => {
            alert(e);
          })
      }
      this.bookmarks = this.bookmarks.filter(val => !this.selectedBookmarks.includes(val));
      this.deleteBookmarksDialog = false;
      this.selectedBookmarks = null;
    },
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    },
    toggle_setBookmarkCd(event, bookmark_cd){
      this.$refs.menu1.toggle(event);
      this.selectedBookmarkCd = bookmark_cd;
    },
    gotoMeigaraChart(code){
      this.$router.push('/meigarachart/' + code);
    },
  }
}
</script>

<style lang="scss" scoped>
.trendchart-img {
  opacity: 0.8;
  border: solid 1px #cccccc;
  border-radius: 5px;  
  width: 136px;
  height: 35px;
}
.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;
    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}
</style>